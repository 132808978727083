import { createApp } from "vue";
import App from "./App.vue";
import VueToast from "vue-toast-notification";
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";

import i18n from "@/core/plugins/i18n";

import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";

// import Vue from 'vue';
import InlineSvg from 'vue-inline-svg';
// Vue.component('inline-svg', InlineSvg);
import BootstrapVue3 from 'bootstrap-vue-3'


// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'

import "@/core/plugins/keenthemes";
import "@/core/plugins/prismjs";
import "bootstrap";
import { initLang } from "./core/helpers/funs";
import Setting from "@/core/config/Setting";

// import vuetify from './plugins/vuetify'
// import { loadFonts } from './plugins/webfontloader'
(window as any).missingKeys = {'ar':{},'en':{}}
//loadFonts()
const app = createApp(App);
ApiService.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();
initLang();
//
Setting.loadFile().then(()=>{
    app
    .use(router)
    //.use(vuetify)
      .use(store)
      .use(i18n)
      .use(ElementPlus)
      .use(VueToast, {
          position: "top-right",
          dismissible: true,
          pauseOnHover: true,
      })
        .use(BootstrapVue3)
        .mount('#app')

});
