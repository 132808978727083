const langs = {
  en: {
    flag: "/media/flags/united-kingdom.svg",
    name: "English",
  },
  ar: {
    flag: "/media/flags/saudi-arabia.svg",
    name: "العريبة",
  },
  // de: {
  //   flag: "/media/flags/germany.svg",
  //   name: "Deutsch",
  // },
  // tr: {
  //   flag: "/media/flags/turkey.svg",
  //   name: "Türkçe",
  // },
  // fr: {
  //   flag: "/media/flags/france.svg",
  //   name: "français",
  // },
  // nl: {
  //   flag: "/media/flags/netherlands.svg",
  //   name: "Nederlands",
  // },
};

export default langs;
