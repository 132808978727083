import { App } from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/JwtService";
import { AxiosResponse, AxiosRequestConfig } from "axios";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import router from "@/router";
import {useI18n} from "vue-i18n";

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
  /**
   * @description property to share vue instance
   */
  public static errors = [];
  public static submited = false;
  public static production = false;
  public static URL = //"http://evaluation.elprogrammer.net/api/";
  (process.env.NODE_ENV == "development" ? "http://127.0.0.1:8000" : location.origin)  + '/api/';
  public static BaseURL  = process.env.NODE_ENV == "development" ? "http://127.0.0.1:8000" : location.origin;
  public static vueInstance: App;

  public static setError(errr) {
    ApiService.errors = errr;
  }

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    ApiService.vueInstance = app;
    ApiService.vueInstance.use(VueAxios, axios);

    ApiService.vueInstance.axios.defaults.baseURL = ApiService.URL;
    ApiService.vueInstance.axios.interceptors.response.use(
      (response) => {
        ApiService.errors = [];
        // handlle 404 response
        return response;
      },
      (error) => {
        if (error.response && error.response.data) {
          if (error.response.data.message) {
            Swal.fire({
              text: error.response.data.message,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: 'try again',
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          }

          if (error.response.data.errors) {
            ApiService.errors = error.response.data.errors;
          }
          switch (error.response.status) {
            case 401:
                JwtService.destroyToken();
                router.push({ name: "sign-in",query:{href:location.pathname} });
                break;
          }
        }
        return error;
      }
    );

    ApiService.setHeader();
  }

  /**
   * @description set the default HTTP request headers
   */

  public static setHeader(): void {
    ApiService.vueInstance.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
    ApiService.vueInstance.axios.defaults.headers.common[
      "Access-Control-Allow-Origin"
    ] = "*";
    ApiService.vueInstance.axios.defaults.headers.common["Accept"] =
      "application/json";

    // if (localStorage.getItem("lang"))
      ApiService.vueInstance.axios.defaults.headers.common["Accept-Languag"] =
        localStorage.getItem("lang")??'en';
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static query(resource: string, params: any): Promise<AxiosResponse> {
    let q = "";
    if (params) {
      q = "?";
      Object.keys(params).forEach((value, index) => {
        if (params[value] && params[value] != false) {
          if (index == 0) {
            q += value + "=" + params[value];
          } else {
            q += "&" + value + "=" + params[value];
          }
        }
      });
    }
    return ApiService.vueInstance.axios.get(resource + q).catch((error) => {
      return error;
      throw new Error(`[KT] ApiService ${error}`);
    });
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static get(resource: string): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(`${resource}`);
  }

  public static getW(resource: string, filters = []): Promise<AxiosResponse> {
    // let string = filters.
    return ApiService.vueInstance.axios.get(`${resource}`);
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static post(resource: string, params): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.post(`${resource}`, params);
  }

  /**
   * @description send the UPDATE HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static update(
    resource: string,
    slug: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params);
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static put(resource: string, params): Promise<AxiosResponse> {
    // useStore().(Mutations.SET_Submitted , true)//.dispatch(, true)
    return ApiService.vueInstance.axios.put(`${resource}`, params);
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static delete(resource: string): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.delete(resource).catch((error) => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  }
}

export default ApiService;
