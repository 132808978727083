import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import JwtService from "@/core/services/JwtService";
import { Actions } from "@/store/enums/StoreEnums";
import langs from "@/core/config/langs";
import i18n from "@/core/plugins/i18n";

const employee = (to, from, next) => {
    if (JwtService.getToken() !== null && JwtService.isEmployee() === true) {
        return next();
    } else {
        router.push({ name: "sign-in", params: { locale: "ar" } , query:{href:location.pathname} });
    }
};
const routes: Array<RouteRecordRaw> = [
    // admin routes

    {
        path: "/:locale",
        component: () => import("@/layout1/Layout.vue"),
        // component: () => import("@/layout1/Layout.vue"),
        beforeEnter: employee,
        children: [
            {
                path: "/:locale/empty",
                name: "empty",
                component: () => import("@/views/empty.vue"),
            },
        ]
    },
    {
        path: "/:locale/form",
        component: () => import("@/layout1/Layout.vue"),
        // component: () => import("@/layout1/Layout.vue"),
        // beforeEnter: employee,
        children: [
            {
                path: "/:locale/form/generate",
                name: "form-generate",
                component: () => import("@/G/create.vue"),
            },
            {
                path: "/:locale/form/Draggable",
                name: "form-Draggable",
                component: () => import("@/views/G/Draggable.vue"),
            },
            {
                path: "/:locale/form/inputs",
                name: "form-inputs",
                component: () => import("@/views/G/input-exs.vue"),
            },
        ]// Draggable
    },
    {
        path: "/:locale",
        // component: () => import("@/layout/site/Layout.vue"),
        component: () => import("@/layout1/Layout.vue"),
        beforeEnter: employee,
        children: [

            {
                path: "/:locale/dashboard",
                name: "dashboard",
                component: () => import("@/views/Dashboard.vue"),
            },
            {
                path: "/:locale/admin/branches/list",
                name: "branches-list",
                component: () => import("@/views/admin/branches/list.vue"),
            },

            {
                path: "/:locale/admin/groups/list",
                name: "groups-list",
                component: () => import("@/views/admin/groups/list.vue"),
            },
            {
                path: "/:locale/admin/question/types/list",
                name: "question-types-list",
                component: () => import("@/views/admin/question/types.vue"),
            },
            {
                path: "/:locale/admin/questions/list",
                name: "question-list",
                component: () => import("@/views/admin/question/questions.vue"),
            },
            {
                path: "/:locale/admin/groups/:id/view",
                name: "group-view",
                component: () => import("@/views/admin/groups/list.vue"),
            },

            {
                path: "/:locale/admin/users/list",
                name: "users-list",
                component: () => import("@/views/admin/users/list.vue"),
            },{
                path: "/:locale/admin/work/types/list",
                name: "work-types-list",
                component: () => import("@/views/admin/emp/workTypes.vue"),
            },{
                path: "/:locale/admin/nationalities/list",
                name: "nationalities-list",
                component: () => import("@/views/admin/emp/nationality.vue"),
            },
            {
                path: "/:locale/admin/roles/list",
                name: "roles-list",
                component: () => import("@/views/admin/manage/roles.vue"),
            },
            {
                path: "/:locale/admin/users/:id/view",
                name: "user-view",
                component: () => import("@/views/admin/users/view.vue"),
            },
            {
              path: "/:locale/admin/branches/:id/view",
              name: "branch-view",
              component: () => import("@/views/admin/branches/view.vue"),
            },


            {
                path: "/:locale/admin/Employees/list",
                name: "employee-list",
                component: () => import("@/views/admin/emp/list.vue"),
            },
            {
                path: "/:locale/admin/Employees/:id/view",
                name: "employee-view",
                component: () => import("@/views/admin/emp/view.vue"),
            },

            {
                path: "/:locale/profile",
                name: "emp-setting",
                component: () => import("@/views/admin/emp/setting.vue"),
            },

            {
                path: "/:locale/admin/employees-reviews/list",
                name: "branches-reviews-list",
                component: () => import("@/views/admin/reviewBranches/list.vue"),
            },
            {
                path: "/:locale/admin/locations-reviews/list",
                name: "branches-reviews-list",
                component: () => import("@/views/admin/reviewBranches/list.vue"),
            },
            // {
            //     path: "/:locale/admin/locations-emp-reviews/list",
            //     name: "branches-reviews-list",
            //     component: () => import("@/views/admin/reviewBranches/list.vue"),
            // },
            {
                path: "/:locale/admin/employees-reviews/:id/view",
                name: "branches-reviews-view",
                component: () => import("@/views/admin/reviewBranches/reviewView.vue"),
            },

            {
                path: "/:locale/admin/reviews/:id/view",
                name: "reviews-view",
                component: () => import("@/views/admin/reviews/view.vue"),
            },
            {
                path: "/:locale/admin/site/setting",
                name: "site-setting",
                component: () => import("@/views/admin/settings/setting.vue"),
            },
            {
                path: "/:locale/admin/users/groups/list",
                name: "group-users-list",
                component: () => import("@/views/admin/userGroups/view.vue"),
            },
            {
                path: "/:locale/admin/users/groups/:id/view",
                name: "group-users-view",
                component: () => import("@/views/admin/userGroups/view.vue"),
            },

        ],
    },
    {
        path: "/:locale/dashboard",
        component: () => import("@/layout/admin/AuthLayout.vue"),
        children: [
            {
                path: "/:locale/dashboard/sign-in",
                name: "sign-in",
                component: () => import("@/views/admin/auth/SignIn.vue"),
            },
            {
                path: "/:locale/dashboard/password-reset",
                name: "password-reset",
                component: () => import("@/views/admin/auth/PasswordReset.vue"),
            },
            {
                path: "/:locale/dashboard/password-reset/:token",
                name: "password-reset-email",
                component: () => import("@/views/admin/auth/PasswordResetEmail.vue"),
            },
        ],
    },

    {
        // the 404 route, when none of the above matches
        path: "/:locale/404",
        name: "404",
        component: () => import("@/views/Error404.vue"),
    },

    {
        path: "/",
        redirect: () => {
            let lang = "ar";
            navigator.languages.forEach((i) => {
                if (langs[i] || langs[i.split("-").shift() as string]) {
                    lang = i.split("-").shift() as string;
                }
            });
            const language = localStorage.getItem("lang") || lang;
            return { name: "dashboard", params: { locale: language } };
        },
    },
];
const history =
    // createMemoryHistory();
    createWebHistory();

const router = createRouter({
    history: history,
    routes: routes,
});

router.beforeEach((route) => {
    if (localStorage.getItem("lang") != route.params.locale) {
        if (langs[String(route.params.locale)]) {
            localStorage.setItem("lang", route.params.locale.toString());
            i18n.global.locale.value = route.params.locale.toString() as "ar";

            if (localStorage.getItem("lang") == "ar")
                store.dispatch(Actions.SET_RTL, "");
            else store.dispatch(Actions.SET_LTR, "");
        } else {
            return window.location.assign(
                "/" + localStorage.getItem("lang") + "/404"
            );
        }
    }

    // store.dispatch(Actions.VERIFY_AUTH);

    // Scroll page to top on every route change
    setTimeout(() => {
        window.scrollTo(0, 0);
    }, 100);
});
router.afterEach(() => {
    setTimeout(() => {
        // store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
    }, 300);
});
export default router;
